import { RouteInfo } from './sidebar.metadata';

/*
moduleName :
    desc  : ACL use
    value : API url after /api/v1/.
    expl  : url  "http://demoao.neofruition.co.in:83/api/v1/chemical/product" => "chemical/product"
*/

export const CHEMICAL_ROUTES_LIST: RouteInfo[] = [
  {
    path: '/module/dashboard',
    title: 'Dashboard',
    titleKey: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    moduleName: 'dashboard',
    extralink: false,
    submenu: [],
    imgUrl: 'assets/images/dashboard-icon.png',
  },
  {
    path: '',
    title: 'Masters',
    titleKey: 'Masters',
    icon: 'mdi mdi-bookmark-plus-outline',
    class: 'has-arrow',
    moduleName: 'masters',
    imgUrl: 'assets/images/masters-icon.png',
    extralink: false,
    submenu: [
      {
        path: '/module/party/partyList',
        title: 'Business Partner',
        titleKey: 'Party',
        moduleName: 'party',
        icon: 'mdi mdi-account-box-outline',
        class: '',
        imgUrl: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/bom',
        title: 'BOM',
        titleKey: 'bom',
        moduleName: 'chemical/bom',
        icon: 'mdi mdi-basket-fill',
        class: '',
        imgUrl: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/product',
        title: 'Item',
        titleKey: 'Material',
        moduleName: 'chemical/item',
        icon: 'mdi mdi-package-variant',
        class: '',
        imgUrl: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/mould',
        title: 'Mould',
        titleKey: 'Mould',
        moduleName: 'chemical/mould',
        icon: 'mdi mdi-package-variant',
        class: '',
        imgUrl: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/supporting-material',
        title: 'Supporting Material',
        imgUrl: '',
        titleKey: 'Supporting Material',
        moduleName: 'chemical/supportingMaterial',
        icon: 'mdi mdi-account-box-outline',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/costHead',
        title: 'Cost Head',
        titleKey: 'CostHead',
        moduleName: 'chemical/costHead',
        icon: 'mdi mdi-cube-outline',
        class: '',
        imgUrl: '',
        extralink: false,
        submenu: [],
      },

      {
        path: '/module/uom',
        title: 'UOM',
        imgUrl: '',
        titleKey: 'UOM',
        moduleName: 'uom',
        icon: 'mdi mdi-account-box-outline',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/segment',
        title: 'Segment',
        imgUrl: '',
        titleKey: 'Segment',
        moduleName: 'segment',
        icon: 'mdi mdi-account-box-outline',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/process',
        title: 'Process',
        titleKey: 'Process',
        imgUrl: '',
        moduleName: 'chemical/process',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/stock-area',
        title: 'Stock Area',
        titleKey: 'StockArea',
        imgUrl: '',
        moduleName: 'chemical/stockArea',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/openingstock',
        title: 'Opening Stock Upload',
        titleKey: 'Opening Stock Upload',
        imgUrl: 'assets/images/account-icon.png',
        icon: 'mdi mdi-file-chart',
        class: '',
        moduleName: 'chemical/itemStock',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/processType',
        title: 'Process Type',
        titleKey: 'ProcessType',
        imgUrl: '',
        moduleName: 'processType',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/department',
        title: 'Department',
        titleKey: 'Department',
        imgUrl: '',
        moduleName: 'chemical/department',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/zone',
        title: 'Zone',
        titleKey: 'Zone',
        imgUrl: '',
        moduleName: 'zone',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/machine',
        title: 'Machine',
        titleKey: 'Machine',
        imgUrl: '',
        moduleName: 'chemical/machine',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/packing',
        title: 'Packing',
        titleKey: 'Packing',
        imgUrl: '',
        moduleName: 'chemical/packing',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/transport',
        title: 'Transport',
        titleKey: 'Transport',
        moduleName: 'chemical/transport',
        icon: 'mdi mdi-truck',
        class: '',
        imgUrl: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/location',
        title: 'Location',
        titleKey: 'Location',
        imgUrl: '',
        moduleName: 'erp/location',
        icon: 'mdi mdi-stoage',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/rack/rack',
        title: 'Rack',
        titleKey: 'Rack',
        imgUrl: '',
        moduleName: 'erp/rack',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/branch',
        title: 'Branch',
        titleKey: 'Branch',
        imgUrl: '',
        moduleName: 'erp/branch',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/custom-field',
        title: 'Custom Field',
        titleKey: 'Custom Field',
        imgUrl: '',
        moduleName: 'customField',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/customParameters',
        title: 'Quality Parameters',
        titleKey: 'Quality Parameters',
        imgUrl: '',
        moduleName: 'customParam',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/custom-module-field',
        title: 'Custom Module Field',
        imgUrl: '',
        titleKey: 'Custom Module Field',
        moduleName: 'moduleCustomField',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/funnelStage',
        title: 'Funnel Stage',
        titleKey: 'crm/funnelStage',
        imgUrl: '',
        moduleName: 'crm/funnelStage',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/crmActivity',
        title: 'CRM Activity',
        titleKey: 'CRM Activity',
        imgUrl: '',
        moduleName: 'crm/activity',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/freight',
        title: 'Freight',
        titleKey: 'chemical/freight',
        imgUrl: '',
        moduleName: 'chemical/freight',
        icon: 'mdi mdi-settings',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'CRM',
    titleKey: 'CRM',
    icon: 'mdi mdi-ticket-account',
    class: 'has-arrow',
    moduleName: 'chemical/crm',
    imgUrl: 'assets/images/dashboard-icon.png',
    extralink: false,
    submenu: [
      {
        path: '/module/funnel',
        title: 'Funnel',
        titleKey: 'crm/funnel',
        icon: 'fa fa-filter',
        imgUrl: '',
        moduleName: 'crm/funnel',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/digitalplatform',
        title: 'digitalplatform',
        titleKey: 'digitalplatform',
        icon: 'fa fa-filter',
        imgUrl: '',
        moduleName: 'crm/funnelindiamart',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/quotation',
        title: 'Quotation',
        titleKey: 'quotation',
        imgUrl: '',
        icon: 'mdi mdi-cart-outline',
        moduleName: 'crm/quotation',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/module/reports',
    title: 'Report',
    titleKey: 'chemical/report',
    imgUrl: 'assets/images/reports-icon.png',
    icon: 'mdi mdi-file-chart',
    class: '',
    moduleName: 'chemical/report',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/materialreconciliation',
    title: 'Material-Reconciliation',
    titleKey: 'chemical/materialreconciliation',
    imgUrl: 'assets/images/account-icon.png',
    icon: 'mdi mdi-file-chart',
    class: '',
    moduleName: 'chemical/reconcile',
    extralink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Supply Chain',
    titleKey: 'Account',
    icon: 'mdi mdi-ticket-account',
    class: 'has-arrow',
    imgUrl: 'assets/images/account-icon.png',
    moduleName: 'supplyChain',
    extralink: false,
    submenu: [
      {
        path: '/module/purchase-order',
        title: 'Purchase Order',
        titleKey: 'Purchase Order',
        imgUrl: '',
        icon: 'mdi mdi-truck',
        moduleName: 'chemical/purchaseOrder',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/sales-order/salesOrderList',
        title: 'Sales Order',
        imgUrl: '',
        titleKey: 'Sales Order',
        icon: 'mdi mdi-cart-outline',
        moduleName: 'chemical/salesOrder',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/module/approval-generic/approvalList',
    imgUrl: 'assets/images/approval-configuration.png',
    title: 'Pending Approval',
    titleKey: 'pendingApproval',
    icon: 'mdi mdi-account',
    moduleName: 'pendingApproval',
    class: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Inventory',
    titleKey: 'Inventory',
    icon: 'mdi mdi-factory',
    imgUrl: 'assets/images/inventory-icon.png',
    class: 'has-arrow',
    moduleName: 'inventory',
    extralink: false,
    submenu: [
      {
        path: '/module/inward-stock',
        title: 'Inventory Stock',
        titleKey: 'Inventory Stock',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/itemStock/stock',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/pre-inward/addUpdate',
        title: 'Add Pre Inward',
        titleKey: 'preInwardAdd',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/preInward/addUpdate',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/pre-inward',
        title: 'Pre Inward List',
        titleKey: 'preInwardList',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/preInwardList',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/inward/list/addUpdate',
        title: 'Add Inward',
        titleKey: 'inwardAdd',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/inward/addUpdate',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/inward/list',
        title: 'Inward List',
        titleKey: 'inwardList',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/inwardList',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/inward-foundary/addUpdate',
        title: 'Add Inward',
        titleKey: 'inwardAdd',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/foundaryInward/addUpdate',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/inward-foundary',
        title: 'Inward List',
        titleKey: 'inwardList',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/foundaryInwardList',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/supporting-material-inward',
        title: 'Supporting Material Inward',
        titleKey: 'Supporting Material Inward',
        icon: 'mdi mdi-beaker',
        imgUrl: '',
        moduleName: 'chemical/sm-inward',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/packing-type-inward',
        title: 'Packing Type Inward',
        titleKey: 'Packing Type Inward',
        icon: 'mdi mdi-beaker',
        imgUrl: '',
        moduleName: 'chemical/packingTypeInward',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/materialRequest',
        title: 'Material Request',
        titleKey: 'Material Request',
        icon: 'fa fa-request',
        imgUrl: '',
        moduleName: 'chemical/materialRequest',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/storeManagement',
        title: 'Material Allocation',
        titleKey: 'Material Allocation',
        icon: 'fa fa-request',
        imgUrl: '',
        moduleName: 'chemical/storeManagement/allocate',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/stock-transfer',
        title: 'Stock Transfer',
        titleKey: 'StockTransfer',
        icon: 'fa fa-request',
        imgUrl: '',
        moduleName: 'chemical/stockTransfer',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/transfer-approval',
        title: 'Transfer Validation',
        titleKey: 'transferApproval',
        icon: 'fa fa-request',
        imgUrl: '',
        moduleName: 'chemical/transferApproval',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/reconciliation',
        title: 'Reconciliation',
        titleKey: 'Reconciliation',
        icon: 'fa fa-request',
        imgUrl: '',
        moduleName: 'chemical/storeManagement/reconcile',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/module/labTest',
    imgUrl: 'assets/images/labtest-icon.png',
    title: 'Lab Test',
    titleKey: 'LabTest',
    icon: 'mdi mdi-bookmark-plus-outline',
    class: '',
    moduleName: 'chemical/labTest',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/Planning-Scheduling',
    imgUrl: 'assets/images/masters-icon.png',
    title: 'Planning & Scheduling',
    titleKey: 'Planning & Scheduling',
    icon: 'mdi mdi-chip',
    class: '',
    moduleName: 'chemical/planning_scheduling',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/planning',
    imgUrl: 'assets/images/masters-icon.png',
    title: 'Planning',
    titleKey: 'Planning',
    icon: 'mdi mdi-calendar',
    class: '',
    moduleName: 'chemical/only_planning',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/production-batch',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Production Batch',
    titleKey: 'ProductionBatch',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'chemical/productionbatch',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/lot-wise-production-batch',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Lot Wise Production Batch',
    titleKey: 'Lot Wise ProductionBatch',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'chemical/lotwiseproductionbatch',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/weavingProcess/list',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Weaving Process',
    titleKey: 'weavingProcess',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'chemical/weavingProcess',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/new-production-batch',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Production Batch',
    titleKey: 'NewProductionBatch',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'chemical/newproductionbatch',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/slittingProcess',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Slitting Process',
    titleKey: 'slittingProcess',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'slittingProcess',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/bagMakingProcess',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Bag Making Process',
    titleKey: 'bagMakingProcess',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'bagMakingProcess',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/dailyProductionMachineWise',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Daily Production Machine Wise',
    titleKey: 'dailyProductionMachineWise',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'chemical/dailyProductionMachineWise',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/daily-production',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'Daily Production',
    titleKey: 'DailyProduction',
    icon: 'fa fa-clock-o',
    class: '',
    moduleName: 'chemical/dailyProduction',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/loom-vise-daily-production',
    imgUrl: 'assets/images/productionbatch-icon.png',
    title: 'DP Log',
    titleKey: 'DailyProductionLog',
    icon: 'fa fa-file-text',
    class: '',
    moduleName: 'chemical/loom-vise-daily-production-log',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/packaging',
    imgUrl: 'assets/images/dispatch-icon.png',
    title: 'Packaging Process',
    titleKey: 'Packaging',
    icon: 'mdi mdi-truck',
    class: '',
    moduleName: 'chemical/packingProcess',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/dispatch',
    imgUrl: 'assets/images/dispatch-icon.png',
    title: 'Dispatch',
    titleKey: 'Dispatch',
    icon: 'mdi mdi-truck',
    class: '',
    moduleName: 'chemical/dispatch',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/transferAndDispatch',
    imgUrl: 'assets/images/labtest-icon.png',
    title: 'Job Work',
    titleKey: 'jobWork',
    icon: 'fa fa-cogs',
    class: '',
    moduleName: 'chemical/transfer-and-dispatch',
    extralink: false,
    submenu: [],
  },
  {
    path: '/module/cbl-to-cbl',
    imgUrl: '',
    title: 'Inter Department Transfer',
    titleKey: 'cblToCBL',
    icon: '',
    class: '',
    moduleName: 'erp/cbl-to-cbl',
    extralink: false,
    submenu: [],
  },
  {
    path: '',
    imgUrl: 'assets/images/settings-icon.png',
    // title: 'Machine asset setting',
    title: 'Machine Asset ',
    titleKey: 'MachineAssets',
    icon: 'fa fa-link',
    moduleName: 'chemical/machineAsset',
    class: '',
    extralink: false,
    submenu: [
      {
        path: '/module/machine-asset-setting',
        imgUrl: 'assets/images/setting-icon.png',
        title: 'M/c - Types and Activity ',
        titleKey: 'machineSetting',
        icon: 'mdi mdi-account',
        moduleName: 'machineAssetsActivity',
        // moduleName: '',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/machine-asset-management',
        title: 'Maintenance Activity ',
        titleKey: 'machineAssetManagement',
        icon: 'mdi mdi-account',
        imgUrl: '',
        moduleName: 'machineAssets',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/module/user',
    imgUrl: 'assets/images/staff-icon.png',
    title: 'Staff',
    titleKey: 'Staff',
    icon: 'mdi mdi-account',
    moduleName: 'staff',
    class: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '',
    imgUrl: 'assets/images/settings-icon.png',
    title: 'Settings',
    titleKey: 'Settings',
    icon: 'fa fa-link',
    moduleName: 'chemial/setting',
    class: '',
    extralink: false,
    submenu: [
      {
        path: '/module/message-templates',
        title: 'Message Templates',
        titleKey: 'Message Templates',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/smsTemplate',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/print-templates',
        title: 'Print Templates',
        titleKey: 'Print Templates',
        icon: 'fa fa-retweet',
        imgUrl: '',
        // moduleName: 'chemical/printTemplate',
        moduleName: 'templateConfig',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/part-code',
        title: 'Product Code',
        titleKey: 'Product Code',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/partCodeConfig',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/sysConfig',
        title: 'System Configuration',
        titleKey: 'System Configuration',
        icon: 'fa fa-setting',
        imgUrl: '',
        moduleName: 'sysConfig',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/materialRequestConfig',
        title: 'Material Request Configuration',
        titleKey: 'Material Request Configuration',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/requestApprovalConfig',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/moduleFieldConfig',
        title: 'Module Param Config',
        titleKey: 'Module Param Config',
        icon: 'fa fa-retweet',
        imgUrl: '',
        moduleName: 'chemical/formConfig',
        class: '',
        extralink: false,
        submenu: [],
      },
      {
        path: '/module/approval-configuration',
        title: 'Approval Configuration ',
        titleKey: 'approvalConfiguration',
        icon: 'mdi mdi-account',
        imgUrl: 'assets/images/approval-configuration.png',
        moduleName: 'approvalConfiguration',
        class: '',
        extralink: false,
        submenu: [],
      },
    ],
  },
  {
    path: '/module/aclConfig',
    imgUrl: 'assets/images/ACLconfig-icon.png',
    title: 'ACL Config',
    titleKey: 'ACL Config',
    icon: 'mdi mdi-account',
    moduleName: 'acl',
    class: '',
    extralink: false,
    submenu: [],
  },
];
